import React from 'react';
import "../../css/splash2.css"
import { Typography,Card, CardContent,CardMedia } from '@material-ui/core';
import { CheckCircle } from '@mui/icons-material';
const Suggest = ({ data }) => {
    return (
        <div >
            <a href={data["smart_link"]} className=" flex flex-col justify-center items-center px-3 py-10">
                <div className=' bg-black rounded-lg shadow-md backdrop-blur-sm border border-white border-opacity-30 overflow-y-auto flex flex-col items-baseline m-0  opacity-90 cursor-pointer mt-6 max-w-[450px]'>
                    <div className='w-400px overflow-hidden'>
                    <img src={data.metadata.image} />
                    </div>
                    <div className='flex '>
                    <div className="text-white p-[15px] font-bold break-words max-w-[500px]">
                    {data.metadata.title}
                </div>
                <div className="text-yellow p-[15px] font-bold break-words max-w-[500px]">
                    {data.metadata.username}
                </div>
                </div>

                </div>
           
            </a>
        </div>


      /*   <a href={data["smart_link"]} className="trending-div">
            <div className="Glass" style={{ marginTop: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: "10px", maxWidth: "450px" }}>
                <div className="thumb">
                    <img src={data.metadata.image} />
                </div>

                <div style={{ color: "white", padding: "15px", fontWeight: "700", wordWrap: "break-word", maxWidth: "500px" }}>
                    {data.metadata.title}
                </div>
            </div>
        </a>  */
    );
};

export default Suggest;
